import React from "react"
import { Helmet } from "react-helmet"
import { withPrefix, Link } from "gatsby"
import $ from "jquery"
import Layout from "../components/layout"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactFullpage from '@fullpage/react-fullpage';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import Footer from "../components/footer"
import Drawer from "../components/drawer"

import "../styles/shul.scss"

import hamMenuIcon from "../images/ham_menu_icon.svg"
import hamMenuClose from "../images/ham_menu_close.svg"
import dropDownArrow from "../images/drop-down-icon.svg"
import muteIcon from "../images/icons/Mute.svg"
import unmuteIcon from "../images/icons/Unmute.svg"

import vaziraniV from "../images/vazirani-v.png"
import vaziraniFullLogo from "../images/vazirani-full-logo-yellow.png"
import shulLogo from "../images/shul-logo-yellow.png"

import shulpic1 from "../images/shul/forsir3.png"

import ekonkpic1 from "../images/ekonk/40.png"
// import ekonkpic2 from "../images/ekonk/mike-kiev-Opzk_hvwO9Q-unsplash.jpg"
import ekonkEk from "../images/ekonk/ek white.png"

import shulLoopVideo from "../videos/51 - Shul Final Car Loop Sequence 25 fps.mp4"
import ekonkTopVideo from "../videos/ekonk/ekonk-top-video.mp4"
import ekonkBottomVideo from "../videos/ekonk/Vazirani_2min_FINAL GRADED.mp4"
import goodWoodFestival from "../videos/49 - GoodwoodFOS_VaziraniA.mp4"

import carbonTub from "../images/CARBON TUB_GOLD2.png"
import evPowertrain from "../images/EV POWERTRAIN NEW_GOLD2.png"
import bodyIllustration from "../images/BODY_ILLUSTRATION_GOLD2.png"



//React image gallery plugin
import ImageGallery from 'react-image-gallery';

const shulGallery = [
  {
    original: 'https://i.ibb.co/Xxrf1m1/HOME-PAGE-GALLERY-CHUNKY.jpg',
  },
  {
    original: 'https://i.ibb.co/Q6kJFm5/HOME-PAGE-GALLERY-CHUNKY2.jpg',
  },
  {
    original: 'https://i.ibb.co/DWxmMkT/HOME-PAGE-GALLERY-CHUNKY3.jpg',
  },
  {
    original: 'https://i.ibb.co/JFGRwvW/HOME-PAGE-GALLERY-CHUNKYF34.jpg',
  },
];

const SEL = 'custom-section';
const SECTION_SEL = `.${SEL}`;

const pluginWrapper = () => {
  /*
  * require('./fullpage.fadingEffect.min'); // Optional. Required when using the "fadingEffect" extension.
  */
};

// markup
class ShulPage extends React.Component {

  componentDidMount() {
    $("#services").hover(function () {
      $(".drop-down").toggleClass("active");
    });

    $("#services-mobile").click(function () {
      $(".drop-down").toggleClass("active");
    });
    $(".drawer-icon").click(function () {
      $(".drawer").toggleClass("active");
      $(".drawer-icon").toggleClass("close");
      $(".drawer-icon-close").toggleClass("close");
    });

    //ekonk-sec1-video
    $("#ekonk-sec1-video-mute").click(function () {
      // $(".drawer").toggleClass("active");
      $("#ekonk-sec1-video-mute").toggleClass("unmuted");
      $("#ekonk-sec1-video-unmute").toggleClass("muted");

      if ($("#ekonk-sec1-video").prop('muted')) {
        $("#ekonk-sec1-video").prop('muted', false);
      } else {
        $("#ekonk-sec1-video").prop('muted', true);
      }
    });

    //ekonk-sec5-video
    $("#ekonk-sec5-video-mute").click(function () {
      // $(".drawer").toggleClass("active");
      $("#ekonk-sec5-video-mute").toggleClass("unmuted");
      $("#ekonk-sec5-video-unmute").toggleClass("muted");

      if ($("#ekonk-sec5-video").prop('muted')) {
        $("#ekonk-sec5-video").prop('muted', false);
      } else {
        $("#ekonk-sec5-video").prop('muted', true);
      }
    });

    $(window).bind('mousewheel', function (event) {
      $(".ekonk-sec2-bg").addClass("active");
    });

    $(document.body).on('touchmove', onScroll); // for mobile

    // callback
    function onScroll() {
      $(".ekonk-sec2-bg").addClass("active");
    }

    // $(".ekonk-sec1-bg").scroll(function(){
    //   $('html, body').animate({
    //     scrollTop: $(".ekonk-sec2").offset().top
    //   }, 1000);
    // });


    // if (window.location.pathname=='/ekonk') {
    //   var currentLocation = 'firstPage';
    //   // No need to set these inside the event listener since they are always the same.
    //   var firstHeight = $('#firstPage').offset().top,
    //       secondHeight = $('#secondPage').offset().top,
    //       thirdHeight = $('#thirdPage').offset().top;

    //   // Helper so we can check if the scroll is triggered by user or by animation.
    //   var autoScrolling = false;

    //   $(document).scroll(function(e){
    //       var scrolled = $(window).scrollTop();

    //       // Only check if the user scrolled
    //       if (!autoScrolling) {
    //         if (scrolled > 1 && currentLocation == 'firstPage') {
    //               scrollPage(secondHeight, 'secondPage');
    //           } else if (scrolled > secondHeight + 1 && currentLocation == 'secondPage') {
    //               scrollPage(thirdHeight, 'thirdPage');
    //           } else if (scrolled < thirdHeight - 1 && currentLocation == 'thirdPage') {
    //               scrollPage(secondHeight, 'secondPage');
    //           } else if (scrolled < secondHeight - 1 && currentLocation == 'secondPage') {
    //               scrollPage(firstHeight, 'firstPage');
    //           }
    //       }

    //       // Since they all have the same animation, you can avoid repetition
    //       function scrollPage(nextHeight, page) {
    //         currentLocation = page;

    //         // At this point, the page will start scrolling by the animation
    //         // So we switch this var so the listener does not trigger all the if/else
    //         autoScrolling = true;
    //         $('body,html').animate({scrollTop:nextHeight}, 1500, function () {
    //             // Once the animation is over, we can reset the helper.
    //             // Now it is back to detecting user scroll.
    //             autoScrolling = false;
    //         });
    //       }

    //     $('h1').html(scrolled);
    //     $('h1').append("/" + secondHeight);
    //     $('h1').append("/" + thirdHeight);
    //   })
    // }

  }

  state = {
    visibility: false
  }






  render() {

    return (
      <React.Fragment>
        <Helmet>
          <meta charset="utf-8" />
          <title>Vazirani Automotive | Shul</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
          <meta name="theme-color" content="#1E1E24" />
          <meta name="description" content="Redesigning the experience by giving the electric car a 'Soul'." />
          <meta property="og:title" content="Shul | Vazirani Automotive" />
          <meta property="og:image:secure" content="https://i.ibb.co/vxZjxJP/home-thumbnail.jpg" />
          <meta property="og:image" content="https://i.ibb.co/vxZjxJP/home-thumbnail.jpg" />
          <meta property="og:image:secure_url" content="https://i.ibb.co/vxZjxJP/home-thumbnail.jpg" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="400" />
          <meta property="og:image:height" content="300" />
        </Helmet>
        <div>
          <img class="drawer-icon" height="17px" src={hamMenuIcon}></img>
          <img class="drawer-icon-close" height="17px" src={hamMenuClose}></img>

          <Drawer></Drawer>

          <div class="wrapper">

            <div class="ekonk-sec2-bg" style={{ backgroundImage: `url(${shulpic1})` }}>

            </div>


            <Layout>
              <div id="fullpage" class="ekonk-container">
                <ReactFullpage
                  debug
                  pluginWrapper={pluginWrapper}
                  scrollingSpeed={1500}
                  easing={"easeInOutExpo"}



                  render={comp => (
                    <ReactFullpage.Wrapper>
                      <div class="section ekonk-sec1" id="firstPage">
                        {/* <div class="mute-icon" id="ekonk-sec1-video-mute">
                          <img src={muteIcon}/>
                        </div>
                        <div class="unmute-icon" id="ekonk-sec1-video-unmute">
                          <img src={unmuteIcon}/>
                        </div> */}
                        <div class="shul-vazirani-logo">
                          <Link to="/"><img src={vaziraniFullLogo}></img></Link>
                        </div>
                        <div class="gradient-dark-overlay"></div>
                        <div class="header">
                          <div class="shul-v-logo">
                            <Link to="/">
                              <img src={vaziraniV}></img>
                            </Link>
                          </div>
                          <div class="nav" style={{ justifySelf: 'right' }}>
                            <Link fade to="/">Shul</Link>
                            <span class="shul-u">_</span>
                          </div>
                          {/* <div class="divider"></div> */}
                          <div class="nav">
                            <Link fade to="/ekonk">Ekonk</Link>
                            {/* <a id="services">Ekonk
                            <img class="drop-down-arrow" src={dropDownArrow}></img>
                        </a> */}
                            {/* <div class="drop-down">
                            <Link to="/design">Design</Link>
                            <div class="divider"></div>
                            <Link to="/engineering">Engineering</Link>
                        </div> */}
                          </div>
                          {/* <div class="divider"></div> */}
                          <div class="nav">
                            <Link fade to="/dico">Dico</Link>
                          </div>


                        </div>

                        <div class="video-container">
                          <video data-autoPlay loop muted playsInline class="full-screen-video" id="ekonk-sec1-video">
                            <source src={shulLoopVideo} type="video/mp4"></source>
                          </video>
                        </div>



                      </div>
                      <div class="section shul-sec2" id="secondPage">

                        <VisibilitySensor
                          onChange={(isVisible) => {
                            this.setState({ visibility: isVisible })
                          }}
                        >
                          <div style={{
                            opacity: this.state.visibility ? 1 : 0,
                            transition: 'all 5s cubic-bezier(0.16, 1, 0.3, 1)'
                          }} class="shul-sec2-container">
                            <div class="divider" />
                            <p>
                              The entire Universe, including all matter, is a matrix of infinite electrical impulses.
                              <br /><br />
                              It’s only natural that all our transportation goes electric.

                              Vazirani aims to help the world make this transition seamless and fun.
                            </p>
                            <div class="divider" />




                            <div class="shul-logo">
                              <img src={shulLogo}></img>
                              <div class="shul-features-desc">
                                <p>Redesigning the experience by giving the electric car a 'Soul'.</p>
                                <p style={{ opacity: '0.5' }}>Coming soon.</p>
                              </div>
                            </div>




                            {/* <LazyLoadImage class="ekonk-sec2-ek-logo" effect="blur" src={ekonkEk}></LazyLoadImage> */}

                          </div>

                        </VisibilitySensor>








                        {/* <LazyLoadImage class="ekonk-sec2-bg" effect="blur" src={ekonkpic1}></LazyLoadImage> */}

                      </div>
                      <div class="section shul-sec3" id="thirdPage">

                        <div class="gradient-dark-overlay"></div>


                        <div class="shul-sec3-container">
                          <div class="ekonk-gallery">
                            {/* <!-- <img src="../images/8DESIGN GALLERY.jpg"> --> */}
                            {/* <div id="auto">
                          <div><img src="../images/8DESIGN GALLERY.jpg" alt="" /></div>
                          <div><img src="../images/9DESIGN GALLERY_Fotor3.jpg" alt="" /></div>
                          <div><img src="../images/13DESIGN GALLERY.jpg" alt="" /></div>
                          <div><img src="../images/DESIGN GALLERY-VR_Fotor3.jpg" alt="" /></div>
                          <div><img src="../images/DESIGN GALLERY_Fotor43.jpg" alt="" /></div>
                          <div><img src="../images/DESIGN GALLERY2_Fotor2_Fotore.jpg" alt="" /></div>
                          <div><img src="../images/5DESIGN GALLERY.jpg" alt="" /></div>
                          <div><img src="../images/11DESIGN GALLERY.jpg" alt="" /></div>
                          <div><img src="../images/5DESIGN GALLERYaero.jpg" alt="" /></div>
                          <div><img src="../images/2DESIGN GALLERY_Fotor.jpg" alt="" /></div>
                      </div> */}
                            <ImageGallery items={shulGallery} slideDuration={1000} slideInterval={5000} showThumbnails={false} showPlayButton={false} autoPlay={true} showFullscreenButton={false} lazyLoad={true} />
                          </div>
                          <div class="shul-features">
                            <div class="shul-features-pictures">
                              <div>
                                <img src={carbonTub}></img>
                                <p>Carbon Fibre<br></br>
                                  Monocoque Cell</p>
                              </div>
                              <div class="divider"></div>
                              <div>
                                <img src={evPowertrain}></img>
                                <p>Electric Powertrain<br></br>&gt;1000hp</p>
                              </div>
                              <div class="divider"></div>
                              <div>
                                <img src={bodyIllustration}></img>
                                <p>Carbon Fibre<br></br>
                                  Body Work</p>
                              </div>

                            </div>
                          </div>

                        </div>

                      </div>



                      <div class="section ekonk-sec5">
                        {/* <div class="mute-icon" id="ekonk-sec5-video-mute">
                          <img src={muteIcon}/>
                        </div>
                        <div class="unmute-icon" id="ekonk-sec5-video-unmute">
                          <img src={unmuteIcon}/>
                        </div> */}
                        {/* <div class="we-build-sexxy">
                                  <span>We Build Sexxy</span>
                              </div> */}
                        <div class="video-container">
                          <video data-autoPlay loop muted playsInline class="full-screen-video" id="ekonk-sec5-video">
                            <source src={goodWoodFestival} type="video/mp4"></source>
                          </video>
                        </div>
                      </div>
                    </ReactFullpage.Wrapper>
                  )}
                />





              </div>


              <Footer></Footer>
            </Layout>

          </div>

        </div>

      </React.Fragment>
    )
  }
}

export default ShulPage
